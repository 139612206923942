import { LonaWebComponent, template } from "@lona/component";
import { Column, Div } from "@lona/component-builtin";
import { component } from "@lona/component-decorators";
import { css } from "@lona/component-styles";
import { MathUtils } from "@lona/math";

const FAKE_STREAKS = [
  [0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 1, 0],
  [0, 0, 0, 0, 0, 1, 0],
  [0, 0, 1, 2, 0, 0, 0],
  [0, 2, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0],
  [0, 1, 1, 2, 4, 0, 0],
  [0, 0, 2, 4, 3, 0, 0],
  [0, 0, 0, 4, 3, 1, 1],
  [1, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0, 0, 0],
];

@component({
  name: "std-streaks",
})
export class Streaks extends LonaWebComponent {
  constructor() {
    super();

    const TODAY = [15, 0];
    let gotToday = false;

    for (let weekNo = 0; weekNo < 18; ++weekNo) {
      const $col = Column.make();
      for (let day = 0; day < 7; ++day) {
        const $day = Div.make();
        $day.classList.add("streak-cell");
        $col.appendChild($day);
        if (FAKE_STREAKS[weekNo][day] != 0) {
          $day.style.background = "#adb88a";
          $day.style.opacity = String(
            MathUtils.lerp(0.3, 1, FAKE_STREAKS[weekNo][day] / 4)
          );
        } else {
          if (gotToday) {
            $day.style.opacity = String(0.3);
          }
        }

        if (TODAY[0] == weekNo && day == TODAY[1]) {
          gotToday = true;
          $day.style.border = "2px solid #444";
        }
      }
      this.$("streak-grid").appendChild($col);
    }
  }

  static $styles: HTMLTemplateElement[] = [
    css`
      :host {
        margin-inline: 8px;
      }

      #root {
        margin-top: 4px;
      }

      .streak-cell {
        height: 12px;
        width: 12px;
        margin: 1px;
        margin-left: 0px;
        margin-right: 2px;
        border-radius: 3px;
        background: white;
      }

      .months {
        color: var(--tertiary-text-color);
        --font-size: var(--small-size);
      }

      .months:not(:first-child) {
        margin-left: 2px;
      }

      .months:not(:last-child) {
        width: 54px;
      }
    `,
  ];
  static $html: Option<HTMLTemplateElement> = template`
    <div id=root>
      <std-row style=margin-bottom:8px;margin-left:0px;>
        <div style="width:calc(0px + 14px)"></div>
        <p class=months style=margin-left:0px;>May</p>
        <p class=months>Jun</p>
        <p class=months>Jul</p>
        <p class=months>Aug</p>
      </std-row>
      <std-row id=streak-grid></std-row>
    </div>
  `;
}
