import { DomUtils } from "./dom";
import { dev } from "./log";

type KnownKeys = "ArrowLeft" | "ArrowRight";
export type HotkeyIdentifier = KnownKeys | string;

/**
 * Maps
 *
 *    shift-[KeyboardEvent.key]
 *    ctrl-[KeyboardEvent.key]
 *    cmd-[KeyboardEvent.key]
 *    [KeyboardEvent.key]
 *
 * to Functions
 */
export namespace Hotkeys {
  let hotkeys: Option<Map<HotkeyIdentifier, EmptyFunction>>;
  let escapeHandler: {
    id: any;
    callback: EmptyFunction;
  }[] = [];

  export function init(initialHotkeys: [HotkeyIdentifier, EmptyFunction][]) {
    hotkeys = new Map([["Escape", onEscape], ...initialHotkeys]);
    document.onkeydown = (e) => {
      const hotkey = DomUtils.hotkeyIdentifier(e);
      if (!hotkey) return;
      Hotkeys.dispatchHotkey(hotkey);
    };
  }

  export function dispatchHotkey(hotkey: string) {
    const cb = hotkeys!.get(hotkey);
    if (cb) cb();
  }

  function onEscape() {
    const handler = escapeHandler.pop();
    if (!handler) return;
    handler.callback();
  }

  export function pushEscapeHandler(id: any, callback: EmptyFunction) {
    escapeHandler.push({
      id,
      callback,
    });
  }

  export function clearEscapeHandler(id: any) {
    escapeHandler = escapeHandler.filter(({id: currentId}) => currentId != id )
  }
}
