import { LonaWebComponent, template } from "@lona/component";
import { component } from "@lona/component-decorators";
import { css } from "../../component-styles";
import { Toaster } from "./toaster";
import { GESTURE_MANAGER } from "../../gesture-manager";
import { Typography } from "../../ui/typography";
import { DomUtils } from "../../dom";
import { Button } from "../button/button";

@component({
  name: "std-toast",
})
export class Toast extends LonaWebComponent {
  onClickClose: Option<EmptyFunction>;
  onClickUndo: Option<EmptyFunction>;

  constructor() {
    super();
    GESTURE_MANAGER.addPointerEvent(this.$("close-button"), {
      onClick: () => this.onClickClose && this.onClickClose(),
    });
  }

  static makeWith(props: Toast.Props): Toast {
    const $t = Toast.make();
    $t.bind(props);
    return $t;
  }

  static info(title: string, options?: Option<Toast.Options>) {
    Toast.makeWith({
      title,
      options,
    }).show();
  }

  static error(title: string, options?: Option<Toast.Options>) {
    Toast.makeWith({
      title,
      variant: "error",
      options,
    }).show();
  }

  static warn(title: string, options?: Option<Toast.Options>) {
    Toast.makeWith({
      title,
      variant: "warn",
      options,
    }).show();
  }

  bind(props: Toast.Props) {
    this.$("title").textContent = props.title;
    const description = props.options?.description;
    if (description) {
      if (typeof description == "string") {
        const $description = Typography.p(description, "std-caption");
        $description.style.setProperty("--margin-top", "8px");
        this.appendChild($description);
      } else {
        description.style.marginTop = "8px";
        this.appendChild(description);
      }
    }

    this.setAttribute("variant", props.variant ?? "");
    this.$("undo-button").toggleAttribute(
      "hidden",
      props.options?.undo == null
    );
    if (props.options?.undo) {
      GESTURE_MANAGER.addPointerEvent(this.$("undo-button"), {
        onClick: () => {
          props.options?.undo && props.options.undo();
          this.onClickUndo && this.onClickUndo();
        },
      });
    }
  }

  show(timeoutMs: number = 4_000) {
    Toaster.instance.get().show(this, timeoutMs);
  }

  static $styles: HTMLTemplateElement[] = [
    Button.$style,
    css`
      #root {
        transform: var(--toast-transform, none);
        transition: var(--toast-transition, transform 0.3s ease);
        --hover-color: var(--black33);
        height: inherit;
        max-height: inherit;
      }

      #cell {
        width: 320px;
        background: #444444;
        border-radius: 8px;
        border: 1px solid var(--divider-color);
        padding: 12px;
        color: white;
        align-items: start;
        height: inherit;
        max-height: inherit;
      }

      :host([variant="error"]) #cell {
        background: #fe5f57;
        --hover-color: var(--black12);
      }

      :host([variant="warn"]) #cell {
        background: #febc30;
        --hover-color: var(--black12);
      }

      #content {
        margin-top: 8px;
        margin-bottom: 8px;
        flex-grow: 1;
      }

      #description {
        --margin-top: 8px;
      }

      [hidden] {
        display: none;
      }

      #close-button {
        border-radius: 5px;
        width: 24px;
        margin-left: 4px;
        padding: 4px;
        margin-top: 2px;
        align-self: stretch;
        cursor: pointer;
        flex-shrink: 0;
      }

      #close-button:hover {
        background: var(--hover-color);
      }

      #undo-button {
        flex-shrink: 0;
      }
    `,
  ];

  static $html: Option<HTMLTemplateElement> = template`
    <div id=root>
      <std-row id=cell>
        <std-col id=content>
          <h1 id=title class=p></h1>
          <slot></slot>
        </std-col>
        <std-spacer></std-spacer>
        <button flat id=undo-button>
          <p style=color:white;>Undo</p>
        </button>
        <div id=close-button>
          <svg id=x fill=currentColor viewBox="0 0 24 24">
            <path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/>
          </svg>
        </div>
      </std-row>
    </div>
  `;
}

export namespace Toast {
  export type Props = {
    title: string;
    variant?: Option<"info" | "error" | "warn">;
    options?: Option<Options>;
  };

  export type Options = Optional<{
    description: string | HTMLElement;
    undo: EmptyFunction;
  }>;

  function showExceptionToast(error: ErrorEvent) {
    const e = error.error;
    if (e instanceof Error) {
      Toast.error(`${e.name}`, {
        description: ExceptionBody.makeWith({
          stack: e.stack,
          cause: e.cause,
          message: e.message,
          name: e.name,
          filename: error.filename,
          lineno: error.lineno,
        }),
      });
    } else {
      Toast.error(`${e.name}`, {
        description: ExceptionBody.makeWith({
          stack: null,
          cause: null,
          message: error.message,
          name: null,
          filename: error.filename,
          lineno: error.lineno,
        }),
      });
    }
  }

  function showUnhandledRejectionToast(error: PromiseRejectionEvent) {
    if (typeof error.reason == "string") {
      Toast.error(`Promise Rejection`, {
        description: ExceptionBody.makeWith({
          stack: null,
          cause: null,
          message: error.reason,
          name: null,
          filename: null,
          lineno: null,
        }),
      });
    } else if (typeof error.reason == "object") {
      Toast.error(`Promise Rejection`, {
        description: ExceptionBody.makeWith({
          stack: error.reason["stack"],
          cause: null,
          message: error.reason["message"],
          name: null,
          filename: null,
          lineno: null,
        }),
      });
    }
  }

  const key = "show-toast-on-exception";
  export function toggleShowToastOnException(force?: boolean) {
    if (force) {
      addEventListener("error", showExceptionToast);
      addEventListener("unhandledrejection", showUnhandledRejectionToast);
      localStorage.setItem(key, "true");
    } else {
      removeEventListener("error", showExceptionToast);
      removeEventListener("unhandledrejection", showUnhandledRejectionToast);
      localStorage.removeItem(key);
    }
  }

  toggleShowToastOnException(localStorage.getItem(key) != null);
}

window["Toast"] = Toast;

@component({
  name: "std-exception-body",
})
export class ExceptionBody extends LonaWebComponent {
  static makeWith(p: ExceptionBody.Props): ExceptionBody {
    const $c = ExceptionBody.make();
    $c.bind(p);
    return $c;
  }

  bind(p: ExceptionBody.Props) {
    console.log(p);
    DomUtils.clearChildren(this.$("root"));
    const filename = p.filename;
    if (filename) {
      this.$("root").appendChild(
        Typography.p(
          `${filename}${p.lineno ? `:${p.lineno}` : ""}`,
          "std-caption"
        )
      );
    }
    if (p.stack) {
      let stack = p.stack.split("\n");
      if (filename) {
        stack = stack.map((l) => l.replace(filename, ""));
      }

      for (const [idx, line] of stack.entries()) {
        this.$("root").appendChild(
          DomUtils.assignStyles(Typography.p(line, "std-caption"), {
            "--margin-top": idx == 0 ? "12px" : "6px",
          })
        );
      }
    }
  }

  static $styles: HTMLTemplateElement[] = [
    css`
      #root * {
        line-break: anywhere;
      }
    `,
  ];

  static $html: Option<HTMLTemplateElement> = template`
    <std-col id=root></std-col>
  `;
}

export namespace ExceptionBody {
  export type Props = {
    name: Option<string>;
    message: Option<string>;
    cause: Option<unknown>;
    filename: Option<string>;
    lineno: Option<number>;
    stack: Option<string>;
  };
}
