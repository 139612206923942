import { dev, info } from "./log";
import { Result } from "./result";
import { TaggedEnum } from "./tagged-enum";

export class ResponseResult<Data> extends Result<
  Data,
  {
    code: number;
    reason: string;
  }
> {
  static expectOrToast<D>(r: ResponseResult<D>): D {
    return r.match({
      ok: (o) => o,
      err: () => {
        throw new Error("request failed");
      },
    });
  }
}

export interface TypedResponse<R> {
  response: Response;
  data: () => Promise<R>;
}

interface FetchConfig {
  query?: any;
  body?: any;
  path?: any;
}

interface Route {
  config?: FetchConfig;
  response?: Option<any>;
}

export interface RouteList {
  [route: string]: {
    get?: Route;
    post?: Route;
    patch?: Route;
    delete?: Route;
  };
}

export class TypedApi<R extends RouteList> {
  static _instance = new TypedApi();
  static instance<R extends RouteList>(): TypedApi<R> {
    return this._instance as unknown as TypedApi<R>;
  }

  async patch<K extends keyof R>(
    route: K,
    config?: R[K] extends { patch: { config: infer C } } ? C : never
  ): Promise<R[K] extends { patch: { response: infer C } } ? C : never> {
    return this.patchRaw(route, config).then(ResponseResult.expectOrToast);
  }

  async patchRaw<K extends keyof R>(
    route: K,
    config?: R[K] extends { patch: { config: infer C } } ? C : never
  ): Promise<
    ResponseResult<R[K] extends { patch: { response: infer C } } ? C : never>
  > {
    const response = await TypedApi.send(
      "PATCH",
      route as string,
      (config as Option<FetchConfig>) ?? {}
    );
    if (response.status != 200) {
      return ResponseResult.err({
        code: response.status,
        reason: await TypedApi.jsonBody(response),
      });
    } else {
      return ResponseResult.ok(await TypedApi.jsonBody(response));
    }
  }

  async post<K extends keyof R>(
    route: K,
    config?: R[K] extends { post: { config: infer C } } ? C : never
  ): Promise<R[K] extends { post: { response: infer C } } ? C : never> {
    return this.postRaw(route, config).then(ResponseResult.expectOrToast);
  }

  async postRaw<K extends keyof R>(
    route: K,
    config?: R[K] extends { post: { config: infer C } } ? C : never
  ): Promise<
    ResponseResult<R[K] extends { post: { response: infer C } } ? C : never>
  > {
    const response = await TypedApi.send(
      "POST",
      route as string,
      (config as Option<FetchConfig>) ?? {}
    );
    if (response.status != 200) {
      return ResponseResult.err({
        code: response.status,
        reason: await TypedApi.jsonBody(response),
      });
    } else {
      return ResponseResult.ok(await TypedApi.jsonBody(response));
    }
  }

  async get<K extends keyof R>(
    route: K,
    config?: R[K] extends { get: { config: infer C } } ? C : never
  ): Promise<R[K] extends { get: { response: infer C } } ? C : never> {
    return this.getRaw(route, config).then(ResponseResult.expectOrToast);
  }

  async getRaw<K extends keyof R>(
    route: K,
    config?: R[K] extends { get: { config: infer C } } ? C : never
  ): Promise<
    ResponseResult<R[K] extends { get: { response: infer C } } ? C : never>
  > {
    const response = await TypedApi.send(
      "GET",
      route as string,
      (config as Option<FetchConfig>) ?? {}
    );
    if (response.status != 200) {
      return ResponseResult.err({
        code: response.status,
        reason: await TypedApi.jsonBody(response),
      });
    } else {
      return ResponseResult.ok(await TypedApi.jsonBody(response));
    }
  }

  async delet<K extends keyof R>(
    route: K,
    config?: R[K] extends { delete: { config: infer C } } ? C : never
  ): Promise<R[K] extends { delete: { response: infer C } } ? C : never> {
    return this.deletRaw(route, config).then(ResponseResult.expectOrToast);
  }

  async deletRaw<K extends keyof R>(
    route: K,
    config?: R[K] extends { delete: { config: infer C } } ? C : never
  ): Promise<
    ResponseResult<R[K] extends { delete: { response: infer C } } ? C : never>
  > {
    const response = await TypedApi.send(
      "DELETE",
      route as string,
      (config as Option<FetchConfig>) ?? {}
    );
    if (response.status != 200) {
      return ResponseResult.err({
        code: response.status,
        reason: await TypedApi.jsonBody(response),
      });
    } else {
      return ResponseResult.ok(await TypedApi.jsonBody(response));
    }
  }

  static async jsonBody(response: Response): Promise<Option<any>> {
    try {
      return await response.json();
    } catch {
      return undefined;
    }
  }

  // NOTE: return type is type erased - no way for the compiler to check
  // the return type of sendMessage so this is correct
  //
  static async send(
    method: string,
    route: string,
    config: FetchConfig
  ): Promise<Response> {
    let resolvedRoute = route;
    const matches = [...route.matchAll(/{([^}]+)}/g)];
    for (const match of matches) {
      // match = ["{param}", "param"]
      // TODO
      // Need error handling - what if config.path.query is null?
      resolvedRoute = resolvedRoute.replace(match[0], config.path[match[1]]);
    }
    const params = new URLSearchParams();
    // const url = new URL(resolvedRoute);
    for (const p in config.query ?? {}) {
      params.append(p, config.query[p]);
    }
    info("network")(
      `[${method}]`,
      resolvedRoute,
      // config.body,
      // params.values()
      config.query
    );

    performance.mark(`[network][${route}] send start`);
    const r = await fetch(
      params.size > 0 ? `${resolvedRoute}?${params.toString()}` : resolvedRoute,
      {
        method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(config.body),
      }
    );
    performance.mark(`[network][${route}] send end`);
    performance.measure(
      `[network][${route}] send`,
      `[network][${route}] send start`,
      `[network][${route}] send end`
    );

    return r;
  }
}
